<template>
  <v-theme-provider>
    <base-section
      id="news-latest"
      class="grey lighten-4"
      space="40"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <div 
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-center">
            <base-section-heading title="Berita, Tips dan Trik" />
            </div>
            <base-body space="0">
               <v-container fluid>
                <v-row dense>
                  <v-col
                    v-for="(data, i) in posts"
                    :key="data.web_news_id"
                    :md="i === 3?'12':'4'"
                    xs="12"
                    cols="12"
                  >
                    <div 
                    data-aos="zoom-in"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="true"
                    data-aos-anchor-placement="top-center">
                    <!-- @click="Detail(data.web_news_id)" -->
                    <v-card 
                    outlined>
                      <v-img
                        :src="data.web_news_src_url"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        :height="i=== 3?'400px':'200px'"
                        lazy-loading
                        :alt="data.web_news_name"
                      >
                        <v-card-title v-html="MaxLength(data.web_news_name,100)" class="body-2"></v-card-title>
                      </v-img>
                      <v-card-actions>
                        {{ FormatDateFullOnlyDate(data.web_news_cdate) }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="Share(data.web_news_id)">
                          <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                    </div>
                  </v-col>
                  <!-- <v-col class="text-center mt-3">
                  <base-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    color="accent"
                    large
                    outlined
                    to="berita-lainnya"
                    v-if="$route.name === 'beranda' || $route.name === ''"
                    class = "text-capitalize"
                  >
                    Berita Lainnya
                  </base-btn>
                </v-col> -->
                </v-row>
              </v-container>
            </base-body>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNewsLatest',
    data: () => ({
            posts: [],
        }),
    created () {
        this.List()
        },
    methods: {
        List () {
          this.loading  = true
          this.posts = [
                        {'web_news_id':'1','web_news_name':'Berita 1','web_news_src_url':'','web_news_cdate':'2023-05-01'},
                        {'web_news_id':'2','web_news_name':'Berita 2','web_news_src_url':'','web_news_cdate':'2023-06-01'},
                        {'web_news_id':'3','web_news_name':'Berita 3','web_news_src_url':'','web_news_cdate':'2023-07-01'},
                        {'web_news_id':'4','web_news_name':'Berita 4','web_news_src_url':'','web_news_cdate':'2023-08-01'},
                        {'web_news_id':'5','web_news_name':'Berita 5','web_news_src_url':'','web_news_cdate':'2023-09-01'},
                        {'web_news_id':'6','web_news_name':'Berita 6','web_news_src_url':'','web_news_cdate':'2023-10-01'},
                        {'web_news_id':'7','web_news_name':'Berita 7','web_news_src_url':'','web_news_cdate':'2023-11-01'},
                        ]
        },
        FormatDateFullOnlyDate (date) {
          return this.$functions.FormatDateFullOnlyDate(date)
        },
        MaxLength (content, max) {
          let returns = content
          if (content.length >= max) {
            returns = content.substr(0,max) + '... '
          }
          return returns
        },
        Detail (url) {
          this.$router.push({name: 'berita-detail', params: { url: url }})
        },
        Share (url) {
          let paramurl = this.$functions.AppURL() +'/berita-detail/' + url
          window.open('https://wa.me/?text='+encodeURIComponent(paramurl))
        }
    }
  }
</script>
